<template>
  <CRow class="justify-content-center m-0 mt-3">
    <div class="col-sm-12 col-lg-10 px-0 row m-0 mb-2">
      <CCol sm="12" class="mb-3">
        <label class="col-sm-12 col-lg-auto px-0 m-0 text-right">
          <b>{{$t('label.customBroker')}}</b>
        </label>
      </CCol>
      <CCol sm="12" lg="6">
        <CSelect
          size="sm"
          :label="$t('label.customBroker')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          addLabelClasses="text-right"
          v-model.trim="$v.CustomBroker.CustomBroker.CustomBrokerId.$model"
          :is-valid="hasError($v.CustomBroker.CustomBroker.CustomBrokerId)"
          :invalid-feedback="errorMessage($v.CustomBroker.CustomBroker.CustomBrokerId)"
          :options="CustomBrokerOptions"
          @change="SelectCustomBroker($event)"
        >
        </CSelect>
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.rifNumber')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.CustomBroker.CustomBrokerRif.$model"
          :is-valid="hasError($v.CustomBroker.CustomBroker.CustomBrokerRif)"
          :invalid-feedback="errorMessage($v.CustomBroker.CustomBroker.CustomBrokerRif)"
          disabled
        />
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.name')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.CustomBroker.CustomBrokerName.$model"
          :is-valid="hasError($v.CustomBroker.CustomBroker.CustomBrokerName)"
          :invalid-feedback="errorMessage($v.CustomBroker.CustomBroker.CustomBrokerName)"
          disabled
        />
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.address')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.CustomBroker.CustomBrokerAddress.$model"
          :is-valid="hasError($v.CustomBroker.CustomBroker.CustomBrokerAddress)"
          :invalid-feedback="errorMessage($v.CustomBroker.CustomBroker.CustomBrokerAddress)"
          disabled
        />
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.mainPhone')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.CustomBroker.CustomBrokerPrimaryPhone.$model"
          :is-valid="hasError($v.CustomBroker.CustomBroker.CustomBrokerPrimaryPhone)"
          :invalid-feedback="errorMessage($v.CustomBroker.CustomBroker.CustomBrokerPrimaryPhone)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="6" >
        <CInput
          size="sm"
          :label="$t('label.secondaryPhone')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.CustomBroker.CustomBrokerSecondaryPhone.$model"
          :is-valid="hasError($v.CustomBroker.CustomBroker.CustomBrokerSecondaryPhone)"
          :invalid-feedback="errorMessage($v.CustomBroker.CustomBroker.CustomBrokerSecondaryPhone)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.mainEmail')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.CustomBroker.CustomBrokerPrimaryEmail.$model"
          :is-valid="hasError($v.CustomBroker.CustomBroker.CustomBrokerPrimaryEmail)"
          :invalid-feedback="errorMessage($v.CustomBroker.CustomBroker.CustomBrokerPrimaryEmail)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.secondaryEmail')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.CustomBroker.CustomBrokerSecondaryEmail.$model"
          :is-valid="hasError($v.CustomBroker.CustomBroker.CustomBrokerSecondaryEmail)"
          :invalid-feedback="errorMessage($v.CustomBroker.CustomBroker.CustomBrokerSecondaryEmail)"
          disabled
        >
        </CInput>
      </CCol>
    </div>
    <div class="col-sm-12 col-lg-10 px-0 row m-0 mb-2">
      <CCol sm="12" lg="auto">
          <label class="col-form-label-sm col-sm-12 col-lg-12 text-right px-1">{{$t('label.isThisTheConsolidator')}}</label>
      </CCol>
      <CCol sm="12" lg="8">
        <div class="form-group row d-flex flex-row align-items-center flex-wrap">
            <label class="col-sm-6 col-lg-auto text-right">NO</label>
            <div class="col-sm-6 col-lg-auto d-flex align-items-center justify-content-end px-2">
                <CSwitch
                  color="watch"
                  variant="3d"
                  type="checkbox"
                  :checked.sync="CustomBroker.ConsolidatorCheck"
                  @update:checked="ChangeConsolidator()"
                />
            </div>
            <label class="col-sm-6 col-lg-auto text-right">{{ `${this.$t('label.yes')}`}}</label>
        </div>
      </CCol>
    </div>
    <div class="col-sm-12 col-lg-10 px-0 row m-0 mb-2" v-show="!this.CustomBroker.ConsolidatorCheck&&this.CustomBroker.CustomBroker.CustomBrokerId">
      <CCol sm="12" class="mb-3">
        <label class="col-sm-12 col-lg-auto px-0 m-0 text-right">
          <b>{{$t('label.consolidator')}}</b>
        </label>
      </CCol>
      <CCol sm="12" lg="6">
        <CSelect
          size="sm"
          :label="$t('label.consolidator')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          addLabelClasses="text-right"
          v-model.trim="$v.CustomBroker.Consolidator.ConsolidatorId.$model"
          :is-valid="hasError($v.CustomBroker.Consolidator.ConsolidatorId)"
          :invalid-feedback="errorMessage($v.CustomBroker.Consolidator.ConsolidatorId)"
          class="remove-space"
          :options="ConsolidatorOptions"
          :value.sync="CustomBroker.Consolidator.ConsolidatorId"
          @change="SelectConsolidator($event)"
        >
        </CSelect>
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.rifNumber')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.Consolidator.ConsolidatorRif.$model"
          :is-valid="hasError($v.CustomBroker.Consolidator.ConsolidatorRif)"
          :invalid-feedback="errorMessage($v.CustomBroker.Consolidator.ConsolidatorRif)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.name')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.Consolidator.ConsolidatorName.$model"
          :is-valid="hasError($v.CustomBroker.Consolidator.ConsolidatorName)"
          :invalid-feedback="errorMessage($v.CustomBroker.Consolidator.ConsolidatorName)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.address')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.Consolidator.ConsolidatorAddress.$model"
          :is-valid="hasError($v.CustomBroker.Consolidator.ConsolidatorAddress)"
          :invalid-feedback="errorMessage($v.CustomBroker.Consolidator.ConsolidatorAddress)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.mainPhone')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.Consolidator.ConsolidatorPrimaryPhone.$model"
          :is-valid="hasError($v.CustomBroker.Consolidator.ConsolidatorPrimaryPhone)"
          :invalid-feedback="errorMessage($v.CustomBroker.Consolidator.ConsolidatorPrimaryPhone)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="6" >
        <CInput
          size="sm"
          :label="$t('label.secondaryPhone')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.Consolidator.ConsolidatorSecondaryPhone.$model"
          :is-valid="hasError($v.CustomBroker.Consolidator.ConsolidatorSecondaryPhone)"
          :invalid-feedback="errorMessage($v.CustomBroker.Consolidator.ConsolidatorSecondaryPhone)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.mainEmail')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.Consolidator.ConsolidatorPrimaryEmail.$model"
          :is-valid="hasError($v.CustomBroker.Consolidator.ConsolidatorPrimaryEmail)"
          :invalid-feedback="errorMessage($v.CustomBroker.Consolidator.ConsolidatorPrimaryEmail)"
          disabled
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="6">
        <CInput
          size="sm"
          :label="$t('label.secondaryEmail')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          v-uppercase
          addLabelClasses="text-right"
          v-model="$v.CustomBroker.Consolidator.ConsolidatorSecondaryEmail.$model"
          :is-valid="hasError($v.CustomBroker.Consolidator.ConsolidatorSecondaryEmail)"
          :invalid-feedback="errorMessage($v.CustomBroker.Consolidator.ConsolidatorSecondaryEmail)"
          disabled
        >
        </CInput>
      </CCol>
    </div>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoBlValidations";
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from "vuex";

function data() {
  return {
    CustomBrokerList: [],
    ConsolidatorList: [],
  };
}

function getService() {
  this.$emit('Loading', true);
  let peticiones = [
    this.$http.ejecutar("GET", "ClientCustomBroker-list", { CompanyBranchId: this.getBranchId, }),
    this.$http.ejecutar("GET", "ClientConsolidator-list", { CompanyBranchId: this.getBranchId, }),
  ];
    Promise.all(peticiones)
     .then((responses) => {
      this.CustomBrokerList = responses[0].data.data;
      this.ConsolidatorList = responses[1].data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$emit('Loading', false);
    });
}

function SelectCustomBroker(event) {
  this.CustomBroker.CustomBroker.CustomBrokerId = event.target.value;
  if (this.CustomBroker.CustomBroker.CustomBrokerId) {
    let data = this.CustomBrokerList.find((item) => item.ClientTpId == this.CustomBroker.CustomBroker.CustomBrokerId);
    //llenado de campos según el agente aduanal
    this.CustomBroker.CustomBroker.CustomBrokerRif = data?.ClientRif ?? '';
    this.CustomBroker.CustomBroker.CustomBrokerName =  data?.ClientName ?? '';
    this.CustomBroker.CustomBroker.CustomBrokerAddress = data?.ClientAddress ?? '';
    this.CustomBroker.CustomBroker.CustomBrokerPrimaryPhone = data?.PrimaryPhone ?? '';
    this.CustomBroker.CustomBroker.CustomBrokerSecondaryPhone = data?.SecondaryPhone ?? '';
    this.CustomBroker.CustomBrokerPrimaryEmail = data?.PrimaryEmail ?? '';
    this.CustomBroker.CustomBroker.CustomBrokerSecondaryEmail = data?.SecondaryEmail ?? '';
    this.$v.CustomBroker.CustomBroker.$touch();
  }else{
    //reseteo de los campos
    this.CustomBroker.CustomBroker.CustomBrokerRif =  '';
    this.CustomBroker.CustomBroker.CustomBrokerName =  '';
    this.CustomBroker.CustomBroker.CustomBrokerAddress = '';
    this.CustomBroker.CustomBroker.CustomBrokerPrimaryPhone = '';
    this.CustomBroker.CustomBroker.CustomBrokerSecondaryPhone = '';
    this.CustomBroker.CustomBroker.CustomBrokerPrimaryEmail = '';
    this.CustomBroker.CustomBroker.CustomBrokerSecondaryEmail = '';

    this.CustomBroker.Consolidator.ConsolidatorRif =  '';
    this.CustomBroker.Consolidator.ConsolidatorName ='';
    this.CustomBroker.Consolidator.ConsolidatorAddress =  '';
    this.CustomBroker.Consolidator.ConsolidatorPrimaryPhone =  '';
    this.CustomBroker.Consolidator.ConsolidatorSecondaryPhone = '';
    this.CustomBroker.Consolidator.ConsolidatorPrimaryEmail = '';
    this.CustomBroker.Consolidator.ConsolidatorSecondaryEmail = '';
  }
}

function SelectConsolidator(event) {
  this.CustomBroker.Consolidator.ConsolidatorId = event.target.value;
  if (this.CustomBroker.Consolidator.ConsolidatorId) {
    let data = this.ConsolidatorList.find((item) => item.ClientTpId == this.CustomBroker.Consolidator.ConsolidatorId);
    //consolidator
    this.CustomBroker.Consolidator.ConsolidatorRif = data?.ClientRif ?? '';
    this.CustomBroker.Consolidator.ConsolidatorName = data?.ClientName ?? '';
    this.CustomBroker.Consolidator.ConsolidatorAddress =  data?.ClientAddress ?? '';
    this.CustomBroker.Consolidator.ConsolidatorPrimaryPhone =  data?.PrimaryPhone ?? '';
    this.CustomBroker.Consolidator.ConsolidatorSecondaryPhone = data?.SecondaryPhone ?? '';
    this.CustomBroker.Consolidator.ConsolidatorPrimaryEmail = data?.PrimaryEmail ?? '';
    this.CustomBroker.Consolidator.ConsolidatorSecondaryEmail = data?.SecondaryEmail ?? '';
    this.$v.CustomBroker.Consolidator.$touch();
  }else{
    //reseteo de los campos
    this.CustomBroker.Consolidator.ConsolidatorRif =  '';
    this.CustomBroker.Consolidator.ConsolidatorName ='';
    this.CustomBroker.Consolidator.ConsolidatorAddress =  '';
    this.CustomBroker.Consolidator.ConsolidatorPrimaryPhone =  '';
    this.CustomBroker.Consolidator.ConsolidatorSecondaryPhone = '';
    this.CustomBroker.Consolidator.ConsolidatorPrimaryEmail = '';
    this.CustomBroker.Consolidator.ConsolidatorSecondaryEmail = '';
    
  }
}

function ChangeConsolidator() {
  if (this.CustomBroker.ConsolidatorCheck&&this.CustomBroker.CustomBroker.CustomBrokerId) {
    // let CustomBrokerData = this.CustomBrokerList.find((item) => item.ClientTpId == this.CustomBroker.CustomBroker.CustomBrokerId);
    // let ConsolidatorData = this.ConsolidatorList.find((item) => item.ClientId == (CustomBrokerData?.ClientId ?? ''));
    // //consolidator
    // this.CustomBroker.Consolidator.ConsolidatorId = ConsolidatorData?.ClientTpId ?? '';
    // this.CustomBroker.Consolidator.ConsolidatorRif = ConsolidatorData?.ClientRif ?? '';
    // this.CustomBroker.Consolidator.ConsolidatorName = ConsolidatorData?.ClientName ?? '';
    // this.CustomBroker.Consolidator.ConsolidatorAddress = ConsolidatorData?.ClientAddress ?? '';
    // this.CustomBroker.Consolidator.ConsolidatorPrimaryPhone = ConsolidatorData?.PrimaryPhone ?? '';
    // this.CustomBroker.Consolidator.ConsolidatorSecondaryPhone = ConsolidatorData?.SecondaryPhone ?? '';
    // this.CustomBroker.Consolidator.ConsolidatorPrimaryEmail = ConsolidatorData?.PrimaryEmail ?? '';
    // this.CustomBroker.Consolidator.ConsolidatorSecondaryEmail = ConsolidatorData?.SecondaryEmail ?? '';
  } else {
    this.CustomBroker.Consolidator.ConsolidatorId = '';
    this.CustomBroker.Consolidator.ConsolidatorRif = '';
    this.CustomBroker.Consolidator.ConsolidatorName = '';
    this.CustomBroker.Consolidator.ConsolidatorAddress = '';
    this.CustomBroker.Consolidator.ConsolidatorPrimaryPhone = '';
    this.CustomBroker.Consolidator.ConsolidatorSecondaryPhone = '';
    this.CustomBroker.Consolidator.ConsolidatorPrimaryEmail = '';
    this.CustomBroker.Consolidator.ConsolidatorSecondaryEmail = '';
  }
}

function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

function Reset() {
  this.CustomBrokerList = [];
  this.ConsolidatorList = [];
}

//computed
function CustomBrokerOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.CustomBrokerList.map(function(e){
    chart.push({
      value: e.ClientTpId, 
      label: e.ClientName,
    })    
  })
  return chart;
}

function ConsolidatorOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ConsolidatorList.map(function(e){
    chart.push({
      value: e.ClientTpId, 
      label: e.ClientName,
    })    
  })
  return chart;
}

export default {
  name: 'custom-broker-tab',
  components: {
  },
  data,
  props: {
    CustomBroker: {
      type: Object,
      required: true,
      default: () => {},
    },
    isEdit: Boolean,
    showModal: Boolean,
    Tab: Boolean,
    Validate: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return GeneralCargoBlValidations();
  },
  methods: {
    getService,
    SelectCustomBroker,
    SelectConsolidator,
    ChangeConsolidator,
    Reset,
  },
  computed:{
    CustomBrokerOptions,
    ConsolidatorOptions,
    getBranchId,
    ...mapState({
      branch: (state) => state.auth.branch,
    }),
  },
  watch: {
    showModal: function (Newval) {
      if (Newval&&this.isEdit) {
        this.$v.CustomBroker.$touch();
      }else{
        this.Reset();
        this.$v.$reset();
      }
    },
    Tab: function (Newval) {
      if (Newval) {
        this.getService();
      }
    },
    Validate: function (NewVal){
      if (NewVal) {
        this.$v.CustomBroker.$touch();
      }
    }
  }
};
</script>
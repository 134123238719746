<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="xl"
      @update:show="Close()"
    >
      <CRow>
        <CCol sm="12">
          <CustomTabs :active-tab="TabIndex" @update:activeTab="handleTab" class="text-table">
            <CustomTab :class="$v.BasicData.$anyError ? 'tab-error' : ''">
              <template #title>
                <span>{{$t('label.basicData')}}</span>
              </template>
              <BasicDataTab
                :BasicData="$v.BasicData.$model"
                :isEdit="isEdit"
                :showModal="show"
                :Tab="TabIndex==0&&show"
                :Validate="Validate"
                @UpdateCommodityList="CommodityList=$event"
                @Loading="Loading=$event"
                @UpdateBasicData="UpdateBasicData"
              />
            </CustomTab>
            <CustomTab :class="$v.PackagingList.$anyError ? 'tab-error' : ''">
              <template #title>
                <span>{{$t('label.detail')}}</span>
              </template>
              <PackagingTab
                :PackagingList="PackagingList"
                :BasicData="$v.BasicData.$model"
                :CommodityList="CommodityList"
                :isEdit="isEdit"
                :showModal="show"
                :Tab="TabIndex==1&&show"
                :Validate="Validate"
                @Loading="Loading=$event"
                @UpdateList="(PackagingList=$event, $v.PackagingList.$touch())"
              />
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>IMDG</span>
              </template>
              <ImdgTab
                :ImdgList="ImdgList"
                :isEdit="isEdit"
                :showModal="show"
                :Tab="TabIndex==2&&show"
                :fgInfo="true"
                @Loading="Loading=$event"
                @UpdateList="ImdgList=$event"
              />
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.seller')}}</span>
              </template>
              <SellerTab
                :Seller="Seller"
                :isEdit="isEdit"
                :showModal="show"
                :Validate="Validate"
              />
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.customBroker')}}</span>
              </template>
              <CustomBrokerTab
                :CustomBroker="CustomBroker"
                :isEdit="isEdit"
                :showModal="show"
                :Tab="TabIndex==4&&show"
                :Validate="Validate"
                @Loading="Loading=$event"
              />
            </CustomTab>
            <CustomTab :class="$v.Consignee.$anyError ? 'tab-error' : ''">
              <template #title>
                <span>{{getClientLabel}}</span>
              </template>
              <ConsigneeTab
                :Consignee="Consignee"
                :isEdit="isEdit"
                :Activity="BasicData.Activity"
                :showModal="show"
                :Tab="TabIndex==5&&show"
                :Validate="Validate"
                @Loading="Loading=$event"
                @UpdateConsignee="$v.Consignee.ClientTp.$touch()"
              />
            </CustomTab>
            <CustomTab>
              <template #title>
                <span>{{$t('label.notifyParty')}}</span>
              </template>
              <NotifyTab
                :Notify="Notify"
                :isEdit="isEdit"
                :showModal="show"
                :Validate="Validate"
              />
            </CustomTab>
            <CustomTab :class="$v.Description.$anyError ? 'tab-error' : ''">
              <template #title>
                <span>{{$t('label.DescriptionOfGoods')}}</span>
              </template>
              <DescriptionTab
                :Description="Description"
                :isEdit="isEdit"
                :showModal="show"
                :Tab="TabIndex==7&&show"
                :Validate="Validate"
                @UpdateDescription="$v.Description.$touch()"
                @Loading="Loading=$event"
              />
            </CustomTab>
            <CustomTab :class="$v.DocumentList.$anyError ? 'tab-error' : ''" v-if="BasicData.Activity">
              <template #title>
                <span>{{$t('label.documentation')}}</span>
              </template>
              <DocumentationTab
                :DocumentList="DocumentList"
                :BasicData="BasicData"
                :StowagePlanningBillOfLadingJson="StowagePlanningBillOfLadingJson"
                :isEdit="isEdit"
                :showModal="show"
                :Tab="TabIndex==8&&show"
                :Validate="Validate"
                @Loading="Loading=$event"
                @UpdateList="(DocumentList=$event, $vDocumentList.$touch())"
                @UpdateBlList="UpdateBlList"
              />
            </CustomTab>
          </CustomTabs>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="Submit"
        >
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </CButton>
        <CButton color="wipe"  @click="Close">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ModalMixin from '@/_mixins/modal';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoBlValidations";
import BasicDataTab from './basic-data-tab';
import PackagingTab from './packaging-tab';
import ImdgTab from './imdg-tab';
import SellerTab from './seller-tab';
import CustomBrokerTab from './custom-broker-tab';
import ConsigneeTab from './consignee-tab';
import NotifyTab from './notify-tab';
import DescriptionTab from './description-tab';
import DocumentationTab from './documentation-tab';

function data() {
  return {
    showModal: false,
    show: false,
    isSubmit: false,
    Loading: false,
    Validate: false,
    TabIndex: 0,
    StowagePlanningBillOfLadingJson: {},
    StowagePlanningBillOfLadingId: '',
    BasicData: {
      Activity: '',
      Bl: '',
      Pol: '',
      Pod: '',
      Booking: '',
      Group: '',
      Classification: '',
      Commodity: '',
      HeadingId: '',
      Weight: 0,
      Volumen: 0,
      Temperature: 0,
      Viscosity: 0,
      Quantity: 0,
      Status: 0,
      FgClassification: false,
    },
    CommodityList: [],
    PackagingList: [],
    ImdgList: [],
    Seller: {
      SellerName: '',
      SellerAddress: '',
      SellerPrimaryPhone: '',
      SellerSecondaryPhone: '',
      SellerPrimaryEmail: '',
      SellerSecondaryEmail: '',
    },
    CustomBroker: {
      CustomBroker: {
        CustomBrokerId: '',
        CustomBrokerRif: '',
        CustomBrokerName: '',
        CustomBrokerAddress: '',
        CustomBrokerPrimaryPhone: '',
        CustomBrokerSecondaryPhone: '',
        CustomBrokerPrimaryEmail: '',
        CustomBrokerSecondaryEmail: '',
      },
      Consolidator: {
        ConsolidatorId: '',
        ConsolidatorRif: '',
        ConsolidatorName: '',
        ConsolidatorAddress: '',
        ConsolidatorPrimaryPhone: '',
        ConsolidatorSecondaryPhone: '',
        ConsolidatorPrimaryEmail: '',
        ConsolidatorSecondaryEmail: '',
      },
      ConsolidatorCheck: false,
    },
    Consignee: {
      ClientTp: '',
			RifClient: '',
      ConsigneeName: '',
      ConsigneePrimaryPhone: '',
      ConsigneePrimaryEmail: '',
      ConsigneeAddress: '',
      ConsigneeSecondaryPhone: '',
      ConsigneeSecondaryEmail: ''
    },
    Notify: {
      NotifyToName: '',
      NotifyToAddress: '',
      NotifyToPrimaryPhone: '',
      NotifyToSecondaryPhone: '',
      NotifyToPrimaryEmail: '',
      NotifyToSecondaryEmail: ''
    },
    Description: {
      DescriptionOfGoods: ''
    },
    DocumentList: [],
  }
}

//methods
function Submit() {
  try {
    this.Loading = true;
    this.isSubmit = true;
    this.Validate = true;
    this.$v.$touch();
    if (this.$v.BasicData.$error||this.$v.PackagingList.$error||this.$v.Consignee.$error||this.$v.Description.$error||this.$v.DocumentList.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    let StowagePlanningBillOfLadingJson = {
      StowagePlanningId: this.StowagePlanningId,
      TpCargoId: this.BasicData.Group,
      TpCargoClassifId: this.BasicData.Classification,
      HeadingId: this.BasicData.HeadingId,
      CommodityId: this.BasicData.Commodity,
      PortActivityId: this.BasicData.Activity,
      ClientTpId: this.Consignee.ClientTp,
      BlNro: this.BasicData.Bl,
      LoadPortId: this.BasicData.Pol,
      DischargePortId: this.BasicData.Pod,
      CustomBrokerId: this.CustomBroker.CustomBroker.CustomBrokerId,
      ConsolidatorId: this.CustomBroker.Consolidator.ConsolidatorId,
      FgConsolidator: this.CustomBroker.ConsolidatorCheck ? 1 : 0,
      SellerName: this.Seller.SellerName,
      SellerAddress: this.Seller.SellerAddress,
      SellerPrimaryPhone: this.Seller.SellerPrimaryPhone,
      SellerSecondaryPhone: this.Seller.SellerSecondaryPhone,
      SellerPrimaryEmail: this.Seller.SellerPrimaryEmail,
      SellerSecondaryEmail: this.Seller.SellerSecondaryEmail,
      NotifyToName: this.Notify.NotifyToName,
      NotifyToAddress: this.Notify.NotifyToAddress,
      NotifyToPrimaryPhone: this.Notify.NotifyToPrimaryPhone,
      NotifyToSecondaryPhone: this.Notify.NotifyToSecondaryPhone,
      NotifyToPrimaryEmail: this.Notify.NotifyToPrimaryEmail,
      NotifyToSecondaryEmail: this.Notify.NotifyToSecondaryEmail,
      BookingNumber: this.BasicData.Booking,
      Weight: this.BasicData.Weight,
      Volumen: this.BasicData.Volumen,
      Temperature: this.BasicData.Temperature,
      Viscosity: this.BasicData.Viscosity,
      Quantity: this.BasicData.Quantity,
      DescriptionOfGoods: this.Description.DescriptionOfGoods,
      PackagingJson: this.PackagingList.map(item => {
        return {
          StowagePlanningBillOfLadingPackagingId: item.StowagePlanningBillOfLadingPackagingId ?? '',
          PackagingId: item.PackagingId ?? '',
          HeadingId: item.HeadingId ?? '',
          HeadingName: item.HeadingName ?? '',
          CommodityId: item.CommodityId ?? '',
          Serial: item.Serial ?? '',
          DescriptionOfGoods: item.DescriptionOfGoods ?? '',
          Length: item.Length ?? 0,
          Width: item.Width ?? 0,
          Height: item.Height ?? 0,
          SquareMeters: item.SquareMeters ?? 0,
          Weight: item.Weight ?? 0,
          Volumen: item.Volumen ?? 0,
          Quantity: item.Quantity ?? 0,
          FgExternPackaging: item.FgExternPackaging ? 1 : 0,
          ImdgJson: item.ImdgJson.map(e => {
            return {
              StowagePlanningBillOfLadingImdgId: e.StowagePlanningBillOfLadingImdgId ?? '',
              ImdgClassId: e.ImdgClassId ?? '',
              ImdgId: e.ImdgId ?? '',
              UnNumberId: e.UnNumberId ?? '',
              PackagingGroupId: e.PackagingGroupId ?? '',
              DescriptionOfGoodsDGS: e.DescriptionOfGoodsDGS ?? '',
              Status: e.Status,
            }
          }),
          Status: item.Status,
        }
      }),
      DocumentJson: this.DocumentList.map(item => {
        return {
          StowagePlanningBillOfLadingDocId: item.StowagePlanningBillOfLadingDocId ?? '',
          PortActivictyDocumentCargoId: item.PortActivictyDocumentCargoId ?? '',
          StowagePlanningBlDocumentDs: item.StowagePlanningBlDocumentDs ?? '',
          RouteStowagePlanningBlDocument: item.RouteStowagePlanningBlDocument ?? '',
          Status: item.Status,
        }
      }),
    }

    if (this.isEdit) {
      StowagePlanningBillOfLadingJson.StowagePlanningBillOfLadingId = this.StowagePlanningBillOfLadingId;
      StowagePlanningBillOfLadingJson.Status = Number(this.BasicData.Status);
    }

    let Method = this.isEdit ? 'PUT' : 'POST';
    let Route = this.isEdit ? 'StowagePlanningBillOfLadingGC-update' : 'StowagePlanningBillOfLadingGC-Insert';

    this.$http.ejecutar(Method, Route, StowagePlanningBillOfLadingJson, { root: 'StowagePlanningBillOfLadingJson' })
      .then(async (response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        await this.$emit("submited");
        await this.$emit('Close');
        await this.Reset();
        this.Loading = false;
      }).catch((err) => {
        this.isSubmit = false;
        this.Loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.Loading = false;
    this.notifyError({text: error});
  }
}

function UpdateBasicData(Parameter) {
  if(Parameter=='Bl'){
    this.$v.BasicData.Bl.$touch();
  }else if (Parameter=='Activity') {
    this.$v.BasicData.Activity.$touch();
  }else if(Parameter=='Pol'){
    this.$v.BasicData.Pol.$touch();
  }else if(Parameter=='Pod'){
    this.$v.BasicData.Pod.$touch();
  }else if(Parameter=='Group'){
    this.$v.BasicData.Group.$touch();
  }else if(Parameter=='Classification'){
    this.$v.BasicData.Classification.$touch();
  }else if(Parameter=='Commodity'){
    this.$v.BasicData.Commodity.$touch();
  }
}

function handleTab(tab) {
  this.TabIndex = tab;
}

function getData(item) {
  this.Loading = true;
  let SellerJson = item.SellerJson&&item.SellerJson.length!=0 ? item.SellerJson[0] : {};
  let CustomBrokerJson = item.CustomBrokerJson&&item.CustomBrokerJson.length!=0 ? item.CustomBrokerJson[0] : {};
  let ConsolidatorJson = item.ConsolidatorJson&&item.ConsolidatorJson.length!=0 ? item.ConsolidatorJson[0] : {};
  let ClientTpJson = item.ClientTpJson&&item.ClientTpJson.length!=0 ? item.ClientTpJson[0] : {};
  let NotifyToJson = item.NotifyToJson&&item.NotifyToJson.length!=0 ? item.NotifyToJson[0] : {};
  this.StowagePlanningBillOfLadingId = item.StowagePlanningBillOfLadingId;
  this.BasicData = {
    Activity: item.PortActivityId ?? '',
    Bl: item.NroBl ?? '',
    Pol: item.LoadPortId ?? '',
    Pod: item.DischargePortId ?? '',
    Booking: item.BookingNumber ?? '',
    Group: item.TpCargoId ?? '',
    Classification: item.TpCargoClassifId ?? '',
    Commodity: item.CommodityId ?? '',
    HeadingId: item.HeadingId ?? '',
    Weight: item.Weight ?? 0,
    Volumen: item.Volumen ?? 0,
    Temperature: item.Temperature ?? 0,
    Viscosity: item.Viscosity ?? 0,
    Quantity: item.Quantity ?? 0,
    Status: item.FgActStowPlaBl ? 1 : 0,
    FgClassification: item.FgClassification,
  };
  let PackagingList = item.PackagingJson ?? [];
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  this.PackagingList = PackagingList.map((Packaging, index) => {
    return {
      ...Packaging,
      IdX: index+1,
      PackagingNameEn: Packaging?.PackagingNameEn ?? '',
      PackagingNameEs: Packaging?.PackagingNameEs ?? '',
      HeadingName: item?.[`HeadingName${_lang}`],
      Serial: Packaging?.Serial ?? '',
      ImdgJson: Packaging?.ImdgJson?.map((e, index2) => {
        return {
          ...e,
          IdX: index2+1,
          ImdgClassId: e.ImdgClassId ?? '',
          ImdgId: e.ImdgId ?? '',
          UnNumberId: e.UnNumberId ?? '',
          PackagingGroupId: e.PackagingGroupId ?? '',
          Status: e.FgActBlCargoImdg ? 1 : 0,
        }
      }) ?? [],
      Status: Packaging.FgActBlPackaging ? 1 : 0,
    }
  })
  let ImdgList = item.ImdgJson ?? [];
  this.ImdgList = ImdgList.map((Imdg, index) => {
    return {
      ...Imdg,
      IdX: index+1,
      Status: Imdg.FgActBlCargoImdg ? 1 : 0,
    }
  })
  this.Seller = {
    SellerName: SellerJson.SellerName ?? '',
    SellerAddress: SellerJson.SellerAddress ?? '',
    SellerPrimaryPhone: SellerJson.SellerPrimaryPhone ?? '',
    SellerSecondaryPhone: SellerJson.SellerSecondaryPhone ?? '',
    SellerPrimaryEmail: SellerJson.SellerPrimaryEmail ?? '',
    SellerSecondaryEmail: SellerJson.SellerSecondaryEmail ?? '',
  };
  this.CustomBroker = {
    CustomBroker: {
      CustomBrokerId: CustomBrokerJson.CustomBrokerId ?? '',
      CustomBrokerRif: CustomBrokerJson.CustomBrokerRif ?? '',
      CustomBrokerName: CustomBrokerJson.CustomBrokerName ?? '',
      CustomBrokerAddress: CustomBrokerJson.CustomBrokerAddress ?? '',
      CustomBrokerPrimaryPhone: CustomBrokerJson.CustomBrokerPrimaryPhone ?? '',
      CustomBrokerSecondaryPhone: CustomBrokerJson.CustomBrokerSecondaryPhone ?? '',
      CustomBrokerPrimaryEmail: CustomBrokerJson.CustomBrokerPrimaryEmail ?? '',
      CustomBrokerSecondaryEmail: CustomBrokerJson.CustomBrokerSecondaryEmail ?? '',
    },
    Consolidator: {
      ConsolidatorId: ConsolidatorJson.ConsolidatorId ?? '',
      ConsolidatorRif: ConsolidatorJson.ConsolidatorRif ?? '',
      ConsolidatorName: ConsolidatorJson.ConsolidatorName ?? '',
      ConsolidatorAddress: ConsolidatorJson.ConsolidatorAddress ?? '',
      ConsolidatorPrimaryPhone: ConsolidatorJson.ConsolidatorPrimaryPhone ?? '',
      ConsolidatorSecondaryPhone: ConsolidatorJson.ConsolidatorSecondaryPhone ?? '',
      ConsolidatorPrimaryEmail: ConsolidatorJson.ConsolidatorPrimaryEmail ?? '',
      ConsolidatorSecondaryEmail: ConsolidatorJson.ConsolidatorSecondaryEmail ?? '',
    },
    ConsolidatorCheck: CustomBrokerJson?.CustomBrokerClientId&&ConsolidatorJson?.ConsolidatorClientId ? 
      CustomBrokerJson?.CustomBrokerClientId == ConsolidatorJson?.ConsolidatorClientId : false,
  };
  this.Consignee = {
    ClientTp: ClientTpJson.ClientTpId ?? '',
		RifClient: ClientTpJson.ConsigneeRif ?? '',
    ConsigneeName: ClientTpJson.ConsigneeName ?? '',
    ConsigneePrimaryPhone: ClientTpJson.ConsigneePrimaryPhone ?? '',
    ConsigneePrimaryEmail: ClientTpJson.ConsigneePrimaryEmail ?? '',
    ConsigneeAddress: ClientTpJson.ConsigneeAddress ?? '',
    ConsigneeSecondaryPhone: ClientTpJson.ConsigneeSecondaryPhone ?? '',
    ConsigneeSecondaryEmail: ClientTpJson.ConsigneeSecondaryEmail ?? '',
  };
  this.Notify = {
    NotifyToName: NotifyToJson.NotifyToName,
    NotifyToAddress: NotifyToJson.NotifyToAddress,
    NotifyToPrimaryPhone: NotifyToJson.NotifyToPrimaryPhone,
    NotifyToSecondaryPhone: NotifyToJson.NotifyToSecondaryPhone,
    NotifyToPrimaryEmail: NotifyToJson.NotifyToPrimaryEmail,
    NotifyToSecondaryEmail: NotifyToJson.NotifyToSecondaryEmail
  };
  this.Description = {
    DescriptionOfGoods: item.DescriptionOfGoods
  };
  let DocumentList = item.DocumentJson ?? [];
  this.DocumentList = DocumentList.map((Document, index) => {
    return {
      ...Document,
      IdX: index+1,
      Status: Document.FgActBlDocument ? 1 : 0,
    }
  });
  this.StowagePlanningBillOfLadingJson = {
    StowagePlanningBillOfLadingId: this.StowagePlanningBillOfLadingId,
    StowagePlanningId: this.StowagePlanningId,
    TpCargoId: this.BasicData.Group,
    TpCargoClassifId: this.BasicData.Classification,
    HeadingId: this.BasicData.HeadingId,
    CommodityId: this.BasicData.Commodity,
    PortActivityId: this.BasicData.Activity,
    ClientTpId: this.Consignee.ClientTp,
    BlNro: this.BasicData.Bl,
    LoadPortId: this.BasicData.Pol,
    DischargePortId: this.BasicData.Pod,
    CustomBrokerId: this.CustomBroker.CustomBroker.CustomBrokerId,
    ConsolidatorId: this.CustomBroker.Consolidator.ConsolidatorId,
    FgConsolidator: this.CustomBroker.ConsolidatorCheck ? 1 : 0,
    SellerName: this.Seller.SellerName,
    SellerAddress: this.Seller.SellerAddress,
    SellerPrimaryPhone: this.Seller.SellerPrimaryPhone,
    SellerSecondaryPhone: this.Seller.SellerSecondaryPhone,
    SellerPrimaryEmail: this.Seller.SellerPrimaryEmail,
    SellerSecondaryEmail: this.Seller.SellerSecondaryEmail,
    NotifyToName: this.Notify.NotifyToName,
    NotifyToAddress: this.Notify.NotifyToAddress,
    NotifyToPrimaryPhone: this.Notify.NotifyToPrimaryPhone,
    NotifyToSecondaryPhone: this.Notify.NotifyToSecondaryPhone,
    NotifyToPrimaryEmail: this.Notify.NotifyToPrimaryEmail,
    NotifyToSecondaryEmail: this.Notify.NotifyToSecondaryEmail,
    BookingNumber: this.BasicData.Booking,
    Weight: this.BasicData.Weight,
    Volumen: this.BasicData.Volumen,
    Temperature: this.BasicData.Temperature,
    Viscosity: this.BasicData.Viscosity,
    Quantity: this.BasicData.Quantity,
    DescriptionOfGoods: this.Description.DescriptionOfGoods,
    PackagingJson: this.PackagingList.map(item => {
      return {
        StowagePlanningBillOfLadingPackagingId: item.StowagePlanningBillOfLadingPackagingId ?? '',
        PackagingId: item.PackagingId ?? '',
        HeadingId: item.HeadingId ?? '',
        HeadingName: item.HeadingName ?? '',
        CommodityId: item.CommodityId ?? '',
        Serial: item.Serial ?? '',
        DescriptionOfGoods: item.DescriptionOfGoods ?? '',
        Length: item.Length ?? 0,
        Width: item.Width ?? 0,
        Height: item.Height ?? 0,
        SquareMeters: item.SquareMeters ?? 0,
        Weight: item.Weight ?? 0,
        Volumen: item.Volumen ?? 0,
        Quantity: item.Quantity ?? 0,
        FgExternPackaging: item.FgExternPackaging ? 1 : 0,
        ImdgJson: item?.ImdgJson?.map(e => {
          return {
            StowagePlanningBillOfLadingImdgId: e.StowagePlanningBillOfLadingImdgId ?? '',
            ImdgClassId: e.ImdgClassId ?? '',
            ImdgId: e.ImdgId ?? '',
            UnNumberId: e.UnNumberId ?? '',
            PackagingGroupId: e.PackagingGroupId ?? '',
            DescriptionOfGoodsDGS: e.DescriptionOfGoodsDGS ?? '',
            Status: e.Status,
          }
        }),
        Status: item.Status,
      }
    }),
    DocumentJson: this.DocumentList.map(item => {
      return {
        StowagePlanningBillOfLadingDocId: item.StowagePlanningBillOfLadingDocId ?? '',
        PortActivictyDocumentCargoId: item.PortActivictyDocumentCargoId ?? '',
        StowagePlanningBlDocumentDs: item.StowagePlanningBlDocumentDs ?? '',
        RouteStowagePlanningBlDocument: item.RouteStowagePlanningBlDocument ?? '',
        Status: item.Status,
      }
    }),
    Status: this.BasicData.Status,
  }
  setTimeout(() => {
    this.show = true;
  }, 10);
  this.$v.$touch();
}

async function UpdateBlList() {
  await this.$emit('submited');
}

async function Reset() {
  this.TabIndex = 0;
  this.Validate = false;
  this.isSubmit = false;
  this.StowagePlanningBillOfLadingJson = {};
  this.StowagePlanningBillOfLadingId = '';
  this.CommodityList = [];
  this.BasicData = {
    Activity: '',
    Bl: '',
    Pol: '',
    Pod: '',
    Booking: '',
    Group: '',
    Classification: '',
    Commodity: '',
    HeadingId: '',
    Weight: 0,
    Volumen: 0,
    Temperature: 0,
    Viscosity: 0,
    Quantity: 0,
    Status: 0,
    FgClassification: false,
  };
  this.PackagingList = [];
  this.ImdgList = [];
  this.Seller = {
    SellerName: '',
    SellerAddress: '',
    SellerPrimaryPhone: '',
    SellerSecondaryPhone: '',
    SellerPrimaryEmail: '',
    SellerSecondaryEmail: '',
  };
  this.CustomBroker = {
    CustomBroker: {
      CustomBrokerId: '',
      CustomBrokerRif: '',
      CustomBrokerName: '',
      CustomBrokerAddress: '',
      CustomBrokerPrimaryPhone: '',
      CustomBrokerSecondaryPhone: '',
      CustomBrokerPrimaryEmail: '',
      CustomBrokerSecondaryEmail: '',
    },
    Consolidator: {
      ConsolidatorId: '',
      ConsolidatorRif: '',
      ConsolidatorName: '',
      ConsolidatorAddress: '',
      ConsolidatorPrimaryPhone: '',
      ConsolidatorSecondaryPhone: '',
      ConsolidatorPrimaryEmail: '',
      ConsolidatorSecondaryEmail: '',
    },
    ConsolidatorCheck: false,
  };
  this.Consignee = {
    ClientTp: '',
		RifClient: '',
    ConsigneeName: '',
    ConsigneePrimaryPhone: '',
    ConsigneePrimaryEmail: '',
    ConsigneeAddress: '',
    ConsigneeSecondaryPhone: '',
    ConsigneeSecondaryEmail: ''
  };
  this.Notify = {
    NotifyToName: '',
    NotifyToAddress: '',
    NotifyToPrimaryPhone: '',
    NotifyToSecondaryPhone: '',
    NotifyToPrimaryEmail: '',
    NotifyToSecondaryEmail: ''
  };
  this.Description = {
    DescriptionOfGoods: ''
  };
  this.DocumentList = [];
  setTimeout(() => {
    this.$v.$reset();
  }, 10);
}

async function Close() {
  this.$emit('Close');
  await this.Reset();
}

//computed
function title() {
	if (this.isEdit) {
		return `${this.$t('label.edit')} BL: ${this.GeneralCargoData.NroBl ?? ''}`;
	} else {
		return `${this.$t('label.nuevo')} BL`;
	}
}

function getClientLabel() {
  if(typeof this.BasicData.Activity == 'string' && this.BasicData.Activity.toUpperCase() == process.env.VUE_APP_PORT_ACTIVITY_EXPORT_ID)
    return this.$t('label.exporter');
  else
    return this.$t('label.importer');
}

export default {
  name: 'general-cargo-modal-index',
  components: {
    CustomTabs,
    CustomTab,
    BasicDataTab,
    PackagingTab,
    ImdgTab,
    SellerTab,
    CustomBrokerTab,
    ConsigneeTab,
    NotifyTab,
    DescriptionTab,
    DocumentationTab,
  },
  data,
  props: {
    modal: Boolean, 
    isEdit: Boolean,
    GeneralCargoData: {
      type: Object,
      default: () => {},
    }
  },
  mixins: [ModalMixin],
  validations() {
    return GeneralCargoBlValidations(this.BasicData.FgClassification, this.BasicData.HeadingId, this.BasicData.Activity);
  },
  methods: {
    Submit,
    UpdateBasicData,
    handleTab,
    getData,
    UpdateBlList,
    Reset,
    Close,
  },
	computed:{
		title,
    getClientLabel,
    ...mapState({
      StowagePlanningId: state => state.planificacionestiba.planificacionId,
    }),
	},
  watch: {
    modal: function(Newval){
			this.showModal = Newval;
      if (Newval&&this.isEdit) {
        this.getData(this.GeneralCargoData);
      }else{
        this.show = Newval;
      }
    },
    PackagingList: function(List){
      let ImdgJson = [];
      List.map(e => {
        if (e.Status == 1) {
          e.ImdgJson.map(el => {
            if (el.Status == 1 && !ImdgJson.some(ele => ele.ImdgClassId == el.ImdgClassId && ele.ImdgId == el.ImdgId && ele.PackagingGroupId == el.PackagingGroupId && ele.UnNumberId == el.UnNumberId)) {
              ImdgJson.push(el);
            }
          })
        }
      })
      this.ImdgList = [...ImdgJson];
    }
  },
};
</script>